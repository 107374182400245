import Swiper from "swiper";
import '../Slider/assets/style.scss';

/**
 * Inicializuj kontajner
 * @param {string} container Kde chcem vložiť Swipper
 * @param {boolean} loop Loop
 * @param {number} space medzera
 * @param {number} perView pocet slidov
 * @param {number} tabletSpace medzera
 * @param {number} tabletView pocet slidov
 * @param {number} mobileSpace medzera
 * @param {number} mobileView pocet slidov
 * @param {string} nextEl button next
 * @param {string} prevEl button prev
 * @param {string} effect button prev
 * @param {number} speed rychlost
 * @return Swiper
 */
window.slider = function slider(container = 'swiper-container',
                                loop = true,
                                space = 0,
                                perView = 1,
                                tabletSpace = 30,
                                tabletView = 3,
                                mobileSpace = 15,
                                mobileView = 2,
                                nextEl = 'next-btn',
                                prevEl = 'prev-btn',
                                effect = 'slide',
                                speed = 300) {
    return new Swiper('.' + container, {
        effect: effect,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
        },
        loop: loop,
        speed: speed,
        spaceBetween: space,
        slidesPerView: perView,
        navigation: {
            nextEl: '.' + nextEl,
            prevEl: '.' + prevEl,
        },
        breakpoints: {
            767: {
                slidesPerView: mobileView,
                spaceBetween: mobileSpace,
            },
            991: {
                slidesPerView: tabletView,
                spaceBetween: tabletSpace,
            }
        },
        thumbs: {
            swiper: galleryThumbs()
        }
    });
};


window.galleryThumbs  = function galleryThumbs (container = 'gallery-thumbs') {
    return new Swiper('.' + container, {
        spaceBetween: 2,
        slidesPerView: 4,
        breakpoints: {
            575: {
                spaceBetween: 10,
            },
        },
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
    });
};

window.sliderHome = function sliderHome(container = 'swiper-container',
                                        loop = true,
                                        space = 0,
                                        perView = 1,
                                        tabletSpace = 30,
                                        tabletView = 3,
                                        mobileSpace = 15,
                                        mobileView = 2,
                                        nextEl = 'next-btn',
                                        prevEl = 'prev-btn',
                                        effect = 'slide',
                                        speed = 300,
                                        has_pagination = false,
                                        has_autoplay = false,
                                        el = 'swiper-pagination',
                                        ) {
    return new Swiper('.' + container, {
        effect: effect,
        pagination: !has_pagination ? false : {
            el: '.' + el,
            clickable: true,
        },
        autoplay: !has_autoplay ? false : {
            delay: 5000,
            disableOnInteraction: false,
        },
        loop: loop,
        speed: speed,
        spaceBetween: space,
        slidesPerView: perView,
        navigation: {
            nextEl: '.' + nextEl,
            prevEl: '.' + prevEl,
        },
        breakpoints: {
            767: {
                slidesPerView: mobileView,
                spaceBetween: mobileSpace,
            },
            991: {
                slidesPerView: tabletView,
                spaceBetween: tabletSpace,
            }
        },
    });
};

